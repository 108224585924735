import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Equals : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 6 4" 
        {...props}
    >
        <path d="M5.15088 0C5.4548 0 5.70117 0.246375 5.70117 0.550293C5.70117 0.854211 5.4548 1.10059 5.15088 1.10059H0.550293C0.246375 1.10059 0 0.854211 0 0.550293C0 0.246375 0.246375 0 0.550293 0H5.15088ZM5.15088 2.83691C5.4548 2.83691 5.70117 3.08329 5.70117 3.38721C5.70117 3.69113 5.4548 3.9375 5.15088 3.9375H0.550293C0.246375 3.9375 0 3.69113 0 3.38721C0 3.08329 0.246375 2.83691 0.550293 2.83691H5.15088Z" fill={fill}/>
</svg>
)

export default Equals
