/** @format */

import React from 'react';

const ArrowSelect = () => {
	return (
		<svg
			width='10'
			height='6'
			viewBox='0 0 10 6'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.18519 -0.000362817L10 5.68555L0 5.68555L5.18519 -0.000362817Z'
				fill='#414547'
			/>
		</svg>
	);
};

export default ArrowSelect;
