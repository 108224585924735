/** @format */

import Image from 'next/image';
import React, { useState, useEffect, FC, useRef } from 'react';
import { Interbank } from '@framework/types/home';
import s from './Converter.module.css';
import CustomSelect from '../CustomSelect';
import { OptionsConverter } from '@framework/data/converter';
import { HouseOnly } from '@framework/types/exchangehouses';
import Link from 'next/link';

export interface ConverterProps {
	exchangeDolar: Interbank;
	houseOnly?: HouseOnly | undefined;
	valueCalcular: (
		result: number,
		currencySent: string,
		estado: string
	) => void;
	exchangeEuro: Interbank;
	exchangeYuan: Interbank;
	exchangeLibra: Interbank;
	exchangeCanada: Interbank;
}

const ConverterMultiple: FC<ConverterProps> = ({
	exchangeDolar,
	exchangeEuro,
	exchangeYuan,
	exchangeLibra,
	exchangeCanada,
}) => {
	const [houseChange, setHouseChange] = useState(0.0);
	const [valueDolares, setValueDolares] = useState(0.0);
	const [selectedOptionHouse, setSelectedOptionHouse] = useState('');
	const [inputValue, setInputValue] = useState(0);
	const [changeConvert, setChangeConvert] = useState(true);

	const optionsHouseOnline = OptionsConverter;

	const formattedValue = (value: number) => {
		return value.toLocaleString('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	}
		
	useEffect(() => {
		const dataHouse = exchangeDolar;
		const houseOnly = dataHouse;
		if(!selectedOptionHouse){
			setSelectedOptionHouse('/icons/flagUSA.svg');
		}
	}
	, [exchangeDolar, selectedOptionHouse]);

	const inputHandlerValue = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const enteredName = Number(event.target.value.replace(/[^\d]/g, '')) / 100;
        setValueDolares(Number(enteredName.toFixed(2).toLocaleString().replace(/[^\d]/g, '')) / 100);
		setInputValue(enteredName);
		handleClickCalcular()
	};

	const change = () => {
		setChangeConvert(!changeConvert);
		setValueDolares(0);
		setHouseChange(0);
	}

	const handleoptions = (value: string) => {
		setValueDolares(0);
		setHouseChange(0);
		setSelectedOptionHouse(value);
	};

	const handleClickCalcular = () => {
		let divisaEntry = 0;
		if (!selectedOptionHouse) {
			divisaEntry = Number(changeConvert ? exchangeDolar.buy.cost : exchangeDolar.sale.cost);
		} else {
			const divisaMap: any = {
				'/icons/flagUSA.svg': (changeConvert ? exchangeDolar.buy.cost : exchangeDolar.sale.cost),
				'/icons/cny_flag.svg': (changeConvert ? exchangeYuan.buy.cost : exchangeYuan.sale.cost),
				'/icons/gbp_flag.svg': (changeConvert ? exchangeLibra.buy.cost : exchangeLibra.sale.cost),
				'/icons/cad_flag.svg': (changeConvert ? exchangeCanada.buy.cost : exchangeCanada.sale.cost),
				'/icons/eu_flag.svg': (changeConvert ? exchangeEuro.buy.cost : exchangeEuro.sale.cost),
			};
			divisaEntry = divisaMap[selectedOptionHouse];
		}
		calculate(divisaEntry);
	}
	
	const calculate = (divisaEntry: number) => {
		const result = changeConvert ? (Number(inputValue*10) * divisaEntry) : (Number(inputValue*10) / divisaEntry);	
		setHouseChange(
			Number(
				result
					.toFixed(2)
					.toLocaleString()
					.replace(/[^\d]/g, '')
			) / 100
		);
	}
	

	const divisaSent = (optionsHouseOnline: any, selectedOptionHouse:any) => {
		return (
			<div className='relative divide-x divide-lgray'>
				<CustomSelect
					options={
						optionsHouseOnline
					}
					type={
						'optionConverter'
					}
					onSelectChange={
						handleoptions
					}
					selectValue={
						selectedOptionHouse
					}
				/>
			</div>
		)
	}
	const divisaConvert = () => {
		return (
			<div className={`${s.custom__select2}`}>
			<div className='flex justify-center items-center h-full'>
				<span className='text-gray-700 text-lg md:text-2xl md:mx-4'>PEN</span>
				<Image
				src={'/icons/flagPeru.svg'}
				width={35}
				height={31}
				className='icon_calcular'
				alt='icon mobile'
			/>
			</div>
		</div>
		)
	}

	const selectItem = (value: any) => {
		return (optionsHouseOnline.find((opt) => opt.value === value)?.symbol)
	}

	const selectOptionSent = () => {
		if(changeConvert && selectedOptionHouse){
			return selectItem(selectedOptionHouse);
		}else {
			return 'S/';
		
		}
	}

	const selectOptionReceived = () => {
		if(!changeConvert && selectedOptionHouse){
			return selectItem(selectedOptionHouse);
		}else {
			return 'S/';
		}
	}

	const inputHandlerHouse = () => {
		return null;
	}

	const leftContent = changeConvert ? divisaSent(optionsHouseOnline, selectedOptionHouse) : divisaConvert();
  	const rightContent = changeConvert ? divisaConvert() : divisaSent(optionsHouseOnline, selectedOptionHouse);

	return (
		<>
			<div className='relative mt-6 md:mt-0 md:px-4'>
				<div className='flex justify-between'>
					<div
						className={`block px-2 rounded-t-lg -bottom-5 md:-bottom-0 pb-1.5 relative h-[75px] md:h-[60px] md:w-[8.6875rem] bg-gradient-to-t from-[#017A6F] to-[#80387D]`}>
						<Link href='/envivo' passHref >
							<div className='flex justify-around cursor-pointer'>
								<div className='w-[50px] my-auto pt-2'>
									<Image
										src='/icons/benja.svg'
										width={
											44
										}
										height={
											43
										}
										alt='new Promore'
									/>
								</div>
								<div className=''>
									<p className=' text-white text-[22px] text-center md:flex md:text-[22px] font-bold justify-center pt-1.5 -mb-1'>
										Dólar
									</p>
									<div className='flex'>
										<span
											className={`${s.twinkle} ${s.alert_envivo}`}></span>
										<p className=' text-white text-[9px] flex justify-center text-center md:flex md:text-xs leading-3 font-normal'>
											EN
											VIVO
										</p>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
				<div className='rounded-r-lg rounded-bl-lg relative filter drop-shadow-sm bg-[#017A6F] px-4 md:px-8 pb-8'>
					<div className='flex pt-8'>
						<div className={'mx-auto w-1/2'}>
							{leftContent}
						</div>
						<div className='mx-4 my-2 bg-primary rounded-full h-10 w-11 md:h-10 flex align-middle justify-center p-1.5 cursor-pointer hover:shadow-lg '>
							<button
								onClick={change}>
								<Image
									src='/icons/change.svg'
									width={
										35
									}
									height={
										39
									}
									alt='icon change'
								/>
							</button>
						</div>
						<div className={'mx-auto w-1/2'}>
							{rightContent}
						</div>
					</div> 
					<div className='mx-auto md:w-full mt-8'>
						<div className='relative divide-x h-auto divide-lgray'>
							<div className='block'>
								<p className='text-xs font-bold text-white mb-2'>CALCULO DE DIVISAS</p>
								<span
									className={'absolute py-2.5 px-3 rounded-md text-2xl'}>
										{selectOptionSent()}
								</span>
								<input
									type='text'
									maxLength={
										12
									}
									pattern='[0-9]*'
									value={formattedValue(valueDolares)}
									onChange={inputHandlerValue}
									className={`bg-white w-full py-2.5 pl-10 pr-2 rounded-md text-2xl`}
								/>
							</div>
						</div>
					</div>
					{/* <div className='flex justify-center my-6'>
						<button className='bg-[#0096A6] uppercase w-[140px] h-9 px-4 rounded-3xl shadow-sm shadow-black text-sm text-white font-medium' onClick={handleClickCalcular}>
							CALCULAR
						</button>
					</div> */}
					<div className='mx-auto md:w-full mt-7'>
						<div className='relative mx-auto'>
							<span className='absolute py-2.5 px-3 rounded-md text-2xl'>
							{selectOptionReceived()}
							</span>
							<input
								type='text'
								maxLength={12}
								value={formattedValue(houseChange)}
								onChange={inputHandlerHouse}
								className='bg-white w-full py-2.5 pl-10 pr-2 rounded-md text-2xl'
								placeholder='0.00'
							/>
						</div>
					</div>
					<div className='flex justify-end '>
						<p className='text-xs text-[#D6D6D6] mt-4'>*Valor referencial tipo de cambio paralelo</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConverterMultiple;
