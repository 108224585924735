import { FC, useEffect } from "react";
import cn from 'classnames';

interface AdsSlotRepeatableProps {
    div: string;
    className?: string;
}

const AdsSlotRepeatable:FC<AdsSlotRepeatableProps> = ({ div, className })=>{
    const classNames = cn(
        className
    ) 
    useEffect(()=>{
        const adElement = document.getElementById(div);
        if(adElement){
            const adID = adElement.id = 'cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive'
        }
    },[div])
    return (
        <div id={div} className={`my-5 ${classNames}`}></div>
    )
}

export default AdsSlotRepeatable