import { Interbank } from "@framework/types/home";
import Image from "next/image";
import { FC, useState } from "react";

interface ConverterSimpleProps {
    exchangeCanada: Interbank
}
const ConverterSimple : FC<ConverterSimpleProps> = ({
    exchangeCanada
}) => {
    const [currencyReceived, setCurrencyReceived] = useState("USD");
    const [currencySent, setCurrencySent] = useState("PEN");
    const [input, setInput] = useState(0)
    const [output, setOutput] = useState(0)
    const currencyIcons = {
        USD: {
            icon: '/icons/dolar_canadiense.svg',
            text: 'USD',
        },
        PEN: {
            icon: '/icons/flagPeru.svg',
            text: 'PEN',
        },
    };
    type CurrencyType = keyof typeof currencyIcons;
    const currencyIcon = (sent: boolean, txt: boolean = true) => {
        const currency = sent ? currencySent : currencyReceived;
        const { icon, text } = currencyIcons[currency as CurrencyType];
        return (
            <div className=" p-1 focus:outline-none focus:shadow-outline flex md:mr-2 mr-0 ">
                <Image src={icon} alt="flecha" width={35} height={31}/>
            </div>
        );
    };

    const change = () => {
        setCurrencySent(currencyReceived);
        setCurrencyReceived(currencySent);
    };

    const converter = () => {
        if (currencySent === "PEN") {
            const result = (Number(input) /  Number(exchangeCanada.sale.cost))
            setOutput(Number(result.toFixed(2)))
        }
        if (currencySent === "USD") {
            const result = (Number(input) * Number(exchangeCanada.buy.cost))
            setOutput(Number(result.toFixed(2)))
        }
    };

    return(
        <>
            <div>
                <div className="bg-secondary rounded-lg relative pb-6">
                    <div className="mx-4 md:mx-4 flex py-5 justify-around ">
                        <div className="flex text-white uppercase font-semibold text-xs w-[61px] text-center ">
                            <h3 className="my-auto">Tú envías {currencySent === "PEN" ? "SOLES" : "DÓLARES"}</h3>
                        </div>
                        <div className="focus:outline-none focus:shadow-outline flex">
                            { currencyIcon(true) }
                        </div>
                        <div className="mx-auto my-2 bg-primary rounded-full  w-10 h-10 flex align-middle justify-center p-1.5 cursor-pointer hover:drop-shadow-lg rotate-90" >
                            <button onClick={ change }>
                                <Image src="/icons/change.svg" width={36} height={36} alt="icon change"/>
                            </button>
                        </div>
                        <div className="focus:outline-none focus:shadow-outline flex">
                            { currencyIcon(false) }
                        </div>
                        <div className="flex text-white uppercase font-semibold text-xs w-[66px] text-center ">
                            <h3 className="my-auto">Tú envías {currencySent === "PEN" ? "DÓLARES" : "SOLES"}</h3>
                        </div>
                    </div>
                    <div className="mx-4">
                        <div className="mx-auto md:w-full px-1">
                            <div className="relative divide-x h-auto divide-lgray">
                                <div className="block">
                                    <span className="absolute py-2 px-3 rounded-md text-2xl">{currencySent === "PEN" ? "S/" : "$"}</span>
                                    <input 
                                        id="inputSoles" 
                                        className="bg-white w-full py-2 pl-10 rounded-md text-2xl placeholder:text-black" 
                                        value={isNaN(input) ? 0 : input}
                                        onChange={(e) => setInput(parseFloat(e.target.value))}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button onClick={ converter } className="mt-5 text-white inline-block py-[5px] px-5 bg-[#009688] rounded-full shadow-md">
                            CONVERTIR
                        </button>
                    </div>
                </div>
                <div className="bg-secondary rounded-lg my-2 p-5">
                    <div className="mx-auto md:w-full">
                        <div className="relative divide-x h-auto divide-lgray ">
                            <div className="block">
                                <span className="absolute bg-[#014943] py-2 px-2 rounded-l-md text-2xl text-[#ffe11b]">{currencySent === "PEN" ? "$" : "S/"}</span>
                                <input 
                                    className="bg-[#014943] text-[#ffe11b] w-full py-2 pl-10 rounded-r-md text-2xl disabled:opacity-100" 
                                    value={isNaN(output) ? 0 : output}
                                    disabled={true}
                                    onChange={(e) => setOutput(parseFloat(e.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConverterSimple;