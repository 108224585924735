
import React, { useState, useRef, useEffect, FC } from 'react';
import s from './CustomSelect.module.css';
import ArrowSelect from '../icons/ArrowSelect';
import Image from 'next/image';
import { Option } from '@framework/data/converter';

interface CustomSelectProps {
	options: Option[];
	type: string;
	onSelectChange: (value: string) => void; // Función de devolución de llamada para enviar datos al padre
	selectValue?: string;
}

const CustomSelect: FC<CustomSelectProps> = ({
	options,
	type,
	onSelectChange,
	selectValue,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(selectValue || '');
	const selectRef = useRef<HTMLDivElement>(null);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (value: string) => {
		setSelectedOption(value);
		setIsOpen(false);
		if (onSelectChange) onSelectChange(value);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			selectRef.current &&
			!selectRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (selectValue) {
			setSelectedOption(selectValue);
		} 
		const handleGlobalClick = (event: MouseEvent) => {
			handleClickOutside(event);
		};
		currencyOption(options)

		if (isOpen) {
			document.addEventListener('mousedown', handleGlobalClick);
		}

		return () => {
			document.removeEventListener('mousedown', handleGlobalClick);
		};


	}, [isOpen, options, selectValue]);

	const labelOption = type === 'optionDocument' ? '* DNI' : '* Soy una casa de cambio física';

	const currencyOption = (value:any) => {
		return (
			<div className='focus:outline-none focus:shadow-outline flex mr-2'>
				<p className='text-gray-700 text-lg md:text-2xl mx-3 md:mx-4'>{value.label}</p>
				<Image
					src={value.value}
					width={35}
					height={31}
					className='icon_calcular'
					alt='icon mobile'
				/>
			</div>
		);
	};

	const optionSelect = (type:string) => {
		if(type === 'optionHouse'){
			if(selectedOption){
				return (
					<p className=''>{options.find((opt) => opt.value === selectedOption)?.label }</p>
				)
			}else {
				return (selectedOption ? selectedOption : options[0].label)
			}
		}
		if(type === 'optionDocument'){
			if(selectedOption){
				return (
					<p className=''>{options.find((opt) => opt.value === selectedOption)?.label }</p>
				)
			}else {
				return (selectedOption ? selectedOption : options[0].label)
			}
		}
		if(type === 'optionConverter'){
			if(selectedOption){
				return currencyOption(options.find((opt) => opt.value === selectedOption));
			}else {
				return currencyOption(options[0]);
			}
		}
	}

	const itemSelected = (type:string) => {
		if(type === 'optionHouse' || type === 'optionDocument'){
			return (
				options.map((option, index) => (
					<li
						key={index}
						onClick={() => handleOptionClick(option.value)}
						className={`${
							option.value == selectedOption
								? s.custom__option__selected
								: s.custom__option
						}`}>
						{option.label}
					</li>
				))
			)
			}else {
			return (
				options.map((option, index) => (
					<li
						key={index}
						onClick={() => handleOptionClick(option.value)}
						className={`flex my-6 mx-4 border-b-2 pb-2`}>
						<p className='text-gray-700 text-lg md:text-2xl mx-4'>{option.label}</p>
							<Image
								src={option.value}
								width={35}
								height={31}
								className='icon_calcular'
								alt='icon mobile'
							/>
					</li>
					))
				)
			}
		}
	
	return (
		<div className='relative block'>
			<div
				className={`${type === 'optionConverter' ? s.custom__select2 : s.custom__select}`}
				onClick={() => handleClick()}>
				{optionSelect(type)}
				<div
					className={`${s.custom__icon} ${
						isOpen ? '' : 'rotate-180'
					} ${type === 'optionConverter' ? 'top-5' : 'top-6'}`}>
					<ArrowSelect />
				</div>
			</div>
			{isOpen && (
				<ul className={`${type === 'optionConverter' ? s.custom__content2 : s.custom__content}`}>
					{itemSelected(type)}
				</ul>
			)}
		</div>
	);
};

export default CustomSelect;
