export interface ConverterData {
    color?: string;
    id: string;
    title: string;
    site: string; 
    logo?: string;
    text?: string;
    text2?: string;
}

export interface Option{
    value: string;
    label: string;
    symbol?: string;

}

const ConverterItems: ConverterData[] = [
    {
        id: "02718b48-f0c3-4f27-8786-585c7028f6f5",
        title: "Western Union",
        site: "https://westernunionperu.pe/cambiodemoneda?utm_source=ced&utm_content=calculadora",
        color: "#EFD008",
        logo: "img/logo/wester-union2.svg",
        text: "black",
        text2: "#807E7E"
    },
    {
        id: "c1a9f598-3254-4a6a-b434-866960befff5",
        title: "Yanki",
        site: "https://yanki.pe?utm_source=ced&utm_content=calculadora",
        color: "#202020",
        logo: "img/logo/yanki2.svg",
        text: "white",
        text2: "#D6D6D6"
    },
    {
        id: "060a5586-5c77-44bd-830e-5104c5bb09f4",
        title: "Chapa Cambio",
        site: "https://chapacambio.com/?utm_source=ced&utm_content=calculadora",
        color: "#00DA5A",
        logo: "img/logo/chapa-cambio2.svg",
        text: "white",
        text2: "#FFFFFF"
    },
    // {
    //     id: "8bfb7a0c-e2d0-4ff2-a71f-bdba8f77d0ad",
    //     title: "Rextie",
    //     site: "https://www.rextie.com/rextie-empresas/registro/?utm_source=ced&utm_content=calculadora",
    //     color: "#000096",
    //     logo: "img/logo/rextie-white.svg",
    //     text: "white",
    //     text2: "#FFFFFF"
    // },
]

const OptionsConverter: Option[] = [
    {
        value: '/icons/flagUSA.svg',
        label: 'USD',
        symbol: '$',
    },
    {
        value: '/icons/cad_flag.svg',
        label: 'CAD',
        symbol: '$',
    },
    {
        value: '/icons/eu_flag.svg',
        label: 'EU',
        symbol: '€',
    },
    {
        value: '/icons/cny_flag.svg',
        label: 'CNY',
        symbol: '¥',
    },
    {
        value: '/icons/gbp_flag.svg',
        label: 'GBP',
        symbol: '£',
    }
];

export  {ConverterItems, OptionsConverter};