import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const ArrowNormal : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 18 10" 
        {...props}
    >
        <path d="M17.5552 9.50859C18.1483 9.00051 18.1483 8.17674 17.5552 7.66866L9.33336 0.624347L1.11156 7.66866C0.518395 8.17674 0.518395 9.0005 1.11156 9.50859C1.70473 10.0167 2.66645 10.0167 3.25962 9.50859L9.33336 4.30421L15.4071 9.50859C16.0003 10.0167 16.962 10.0167 17.5552 9.50859Z" fill={fill}/>
</svg>
)
export default ArrowNormal
